import Vue from "vue";
import { Component } from "vue-property-decorator";
import { httpService } from '@/services/http.service';
import dto_user from '@/models/dtos/dto_user';

@Component({
    components: {
    }
})
export default class Login extends Vue {
    private usuario = new dto_user();

    created() {
    }

    login(){
        return httpService.login(this.usuario.email,this.usuario.pwd).then(response => {
            var ret = response;
            if (!ret.error)
            {
                localStorage.setItem('tokenwiki',ret.data);
                this.$router.push({name:'home'});
            } else {
                alert(ret.msg);
            }
        })
        //this.$router.push({name:'listas', params:{id:value.id, titulo:value.tablero}});
    }
}