



















import { Component, Prop, Vue } from "vue-property-decorator";
// import listaModule from "@/store/modules/listaModule";

declare var $: any;
@Component({
  components: {}
})
export default class NavBar extends Vue {
  // private textSearch: string = "";
  mounted() {
    $(document).ready(function() {
      $("#sidebarCollapse").on("click", function() {
        $("#sidebar").toggleClass("active");
        $("#content").toggleClass("active");
        $("#imgnavbar").toggleClass("active");
      });
    });
  }
  logOut() {
    this.$router.push("login");
  }
}
