




























import { Component, Vue } from "vue-property-decorator";
import { Menu } from "@/models/dtos/dto_menu";
import { wikiService } from "@/services/wiki.service";
import listaModule from "@/store/modules/listaModule";
import Utils from "@/shared/helpers/utils";

@Component({
  components: {}
})
export default class Navigation extends Vue {
  private isActive: boolean = false;
  private menuItems: Menu[] = [];

  created() {
    //Cargar todas las categorias y las notas en el menú
    wikiService.getLibretas().then(response => {
      this.menuItems = response.data;
      console.log('MEnus->',this.menuItems);
      this.$router.push("/listanotas");
    });
  }
  itemClick(e: any) {
    listaModule.setLibreta(e.libreta1);
    listaModule.setidLibreta(e.id);
    if (Utils.isMobile() == true) {
      listaModule.loadLista();
      this.$router.push("/listanotas");
    } else {
      listaModule.loadLista();
    }
  }
  click(e:any){
    this.$router.push("/test");
  }
  isMobile() {
    
    return Utils.isMobile();
  }
}
