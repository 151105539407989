import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { store } from '../store';
import { wikiService } from '@/services/wiki.service';
import listaModule from '@/store/modules/listaModule';

import Dto_Nota from '@/models/dtos/dto_nota';

const api = process.env.VUE_APP_URL_API;
const axios = require('axios');


@Module({ name: 'notaModule', store, dynamic: true, })
class NotaModule extends VuexModule {

    public nota: Dto_Nota = new Dto_Nota();

    @Mutation
    public setNota(value: Dto_Nota) {
        this.nota = value;
    }


    @Action
    public async loadNota(id: any) {
        await wikiService.getNotaById(id).then(response => {
            this.context.commit('setNota', response.data);
        })
    }

    @Action
    public async saveNota() {
        if (this.nota.id != 0) {
            await wikiService.saveNota(this.nota.id, this.nota.idlibreta, this.nota.titulo, this.nota.contenido, this.nota.fecha, this.nota.url).then(response => {
                // Show toast
            })
        } else {
            await wikiService.addNota(this.nota.id, this.nota.idlibreta, this.nota.titulo, this.nota.contenido, this.nota.fecha, this.nota.url).then(response=>{
                // show toast and refresh list
                
            })
        }
        listaModule.loadLista();
    }

    @Action
    public async deleteNota(){
        if (this.nota.id != 0) {
            await wikiService.deleteNota(this.nota.id).then(()=>{
                listaModule.loadLista();
            })
        }
    }
}

export default getModule(NotaModule);