import { Vue, Component, Prop } from "vue-property-decorator";
import notaModule from '@/store/modules/notaModule';
import dto_nota from '@/models/dtos/dto_nota';
import listaModule from '@/store/modules/listaModule';
import Editor from '@tinymce/tinymce-vue'
import {
    DxHtmlEditor,
    DxToolbar,
    DxMediaResizing,
    DxItem,
  } from 'devextreme-vue/html-editor';

@Component({
    components: {
     Editor,
     DxHtmlEditor,
     DxToolbar,
     DxMediaResizing,
     DxItem,
    }
})

export default class TarjetaModal extends Vue {

  

    public get nota() {
        return notaModule.nota;
    }
    mounted() {
       
    }
    save() {
        this.nota.idlibreta = listaModule.idlibretaSelected;
        notaModule.setNota(this.nota);
        notaModule.saveNota().then(() => {
            this.$modal.hide('TarjetaModal');
        });
    }
}