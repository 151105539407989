import { httpService } from '@/services/http.service';

const api = process.env.VUE_APP_URL_API;

class WikiService {

  constructor() {
    console.log('App service instance start ...');
  }
  // public getListasByTablero(idtablero: string) {
  //   return httpService.post(`${api}/lista/GetByTablero`, idtablero);
  // }
  // public addTarjeta(id:number,LISTAid:number,titulo:string,contenido:string, etiquetas:string){
  //   return httpService.post(`${api}/tarjeta/Add`, {id:id, LISTAid:LISTAid, titulo:titulo,contenido:contenido,etiquetas:etiquetas});
  // }
  // public updTarjeta(id:number,LISTAid:number,titulo:string,contenido:string, etiquetas:string){
  //   return httpService.post(`${api}/tarjeta/Update`, {id:id, LISTAid:LISTAid, titulo:titulo,contenido:contenido,etiquetas:etiquetas});
  // }
  // public getTarjeta(id:number){
  //   return httpService.post(`${api}/tarjeta/GetId`, id);
  // }
  // public deleteTarjeta(id:number){
  //   return httpService.post(`${api}/tarjeta/Delete`, id);
  // }
  public ValidarToken(){
    return httpService.post(`${api}/Token/ValidarToken`, null);
  }
  public getLibretas(){
    return httpService.post(`${api}/libreta/GetAll`, null);
  }
  public getNotaById(id:number){
    return httpService.post(`${api}/nota/GetId`, id);
  }
  public getNotas(){

  }
  public getAllNotas(){
    return httpService.post(`${api}/nota/GetAll`, null);
  }
  public saveNota(id:number,idlibreta:number,titulo:string,contenido:string, fecha:Date,url:string) {
    return httpService.post(`${api}/nota/Update`, {id:id, idlibreta:idlibreta, titulo:titulo,contenido:contenido,fecha:fecha, url:url});
  }
  public addNota(id:number,idlibreta:number,titulo:string,contenido:string, fecha:Date,url:string) {
    return httpService.post(`${api}/nota/Add`, {id:id, idlibreta:idlibreta, titulo:titulo,contenido:contenido,fecha:fecha, url:url});
  }
  public getNotasByIdlibreta(id:number)  {
    return httpService.post(`${api}/nota/GetByIdlibreta`, id);
  }
  public deleteNota(id:number){
    return httpService.post(`${api}/nota/DeleteAsync`, id);
  }
}

export const wikiService = new WikiService();