export default class Dto_Nota {
    id:number=0;
    idlibreta:number=0;
    titulo:string="";
    contenido:string="";
    url:string="";
    fecha:Date = new Date();
    constructor(){
        this.id = 0;
        this.idlibreta = 0;
        this.titulo = "";
        this.contenido = "";
        this.url = "";
        this.fecha = new Date();
    }
}