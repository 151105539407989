import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { store } from '../store';
import { wikiService } from '@/services/wiki.service';

import Dto_Nota from '@/models/dtos/dto_nota';
import notaModule from '@/store/modules/notaModule';

// const api = process.env.VUE_APP_URL_API;
// const axios = require('axios');


@Module({ name: 'listaModule', store, dynamic: true, })
class listaModule extends VuexModule {


    public notas: Dto_Nota[] = [];
    public libretaSelected: string = '';
    public idlibretaSelected: number = 0;

    @Mutation
    public setLista(value: Dto_Nota[]) {
        notaModule.setNota(new Dto_Nota());
        this.notas = value;
    }
    @Mutation
    public setLibreta(titulo: string) {
        this.libretaSelected = titulo;
        console.log(titulo);
    }
    @Mutation
    public setidLibreta(id: any) {
        this.idlibretaSelected = id;
    }

    @Action
    public async loadLista() {
        if (this.idlibretaSelected != 0) {
            await wikiService.getNotasByIdlibreta(this.idlibretaSelected).then(response => {
                this.context.commit('setLista', response.data);

            })
        } else {
            await wikiService.getAllNotas().then(response => {
                this.context.commit('setLista', response.data);
            })
        }
        
    }

    @Action
    public searchNotes(filter: string) {
        if (filter == '') {
            this.loadLista();
        } else {
            let v = this.notas.filter(p => {
                return p.titulo.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
            })
            this.context.commit('setLista', v);
        }
    }

}

export default getModule(listaModule);