
















import { Component, Prop, Vue } from "vue-property-decorator";
import Navigation from "@/shared/components/navigation/Navigation.vue";
import NavBar from "@/shared/components/nav-bar/NavBar.vue";
import Notas from "@/views/notas/notas";
import NotasMobile from "@/views/notasMobile/notasmobile";
import listaModule from "@/store/modules/listaModule";
import Dto_Nota from "@/models/dtos/dto_nota";
import Utils from "@/shared/helpers/utils";

@Component({
  components: {
    Navigation,
    NavBar,
    Notas,
    NotasMobile
  }
})
export default class Main extends Vue {

  mounted() {
    // Inicializar la pantalla
    listaModule.setLibreta("Todas las notas");
    listaModule.setidLibreta(0);
    listaModule.loadLista();
  }
  isMobile(){
    return Utils.isMobile();
  }
}
