import { render, staticRenderFns } from "./test.html?vue&type=template&id=52ca0ca3&scoped=true&"
import script from "./test.ts?vue&type=script&lang=ts&"
export * from "./test.ts?vue&type=script&lang=ts&"
import style0 from "./test.scss?vue&type=style&index=0&id=52ca0ca3&module=true&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "52ca0ca3",
  null
  
)

export default component.exports