import { render, staticRenderFns } from "./notasmobile.html?vue&type=template&id=23eb3a28&scoped=true&"
import script from "./notasmobile.ts?vue&type=script&lang=ts&"
export * from "./notasmobile.ts?vue&type=script&lang=ts&"
import style0 from "./notasmobile.scss?vue&type=style&index=0&id=23eb3a28&module=true&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "23eb3a28",
  null
  
)

export default component.exports