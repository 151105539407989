import axios, { AxiosInstance, AxiosResponse } from 'axios';
import SRes from '@/models/SRes';

const api = process.env.VUE_APP_URL_API;

export default class HttpService {
 
    public _token: any = localStorage.getItem("tokenwiki");
    private _instance: AxiosInstance = axios.create({
            headers: { 'Content-Type': 'application/json' }
        });

    private _headers: any = null;

    constructor() {
        this.processResponse = this.processResponse.bind(this);
    } 

    //Metodo Generico Get. Antes de hacer la llamada agrega a las cabeceras el Token
    get(url: string, params: any) {
        this.token = localStorage.getItem("tokenwiki");
        this._instance.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;   
        return this._instance.get(url, params).then(this.processResponse).catch(this.processError);
    }

    //Metodo Generico Post. Antes de hacer la llamada agrega a las cabeceras el Token
    post(url: string, params: any) {
        this.token = localStorage.getItem("tokenwiki");
        this._instance.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        return this._instance.post(url, params).then(this.processResponse).catch(this.processError);
    }
    public login(email:string, pwd:string){
        return this.post(`${api}/usuarios/login`, {email:email, pwd:pwd});
      }
    private processResponse(response: AxiosResponse): Promise<any> {
        this.headers = response.headers;
        return new Promise((resolve: any, reject: any) => {
            const res = <SRes>response.data;

            // if (res.error) {
            //     reject(res);
            // } else {
            //     resolve(res);
            // }
            resolve(res);
        });
    }

    private processError(error: any) {
        if (error.response) {
            if (error.response.status == 500){
                //this.$toasted.show(error.response.data.msg, { duration: 3000 });
            }else if(error.response.status == 401){
                //this.$toasted.show(error.response.status + ": " + error.response.statusText, { duration: 3000 });
            }else{
                console.log(error.response);
            }
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
    }

    set token(valor: any ) {
        this._token = valor;
    }

    get token() {
        return this._token;
    }

    get headers() {
        return this._headers;
    }

    set headers(headers: any) {
        this._headers = headers;
    }

}

export const httpService = new HttpService();