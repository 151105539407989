import Vue from "vue";
import { Component } from "vue-property-decorator";
import notaModule from '@/store/modules/notaModule';
import Editor from '@tinymce/tinymce-vue'
import Utils from "@/shared/helpers/utils";
import {
    DxHtmlEditor,
    DxToolbar,
    DxMediaResizing,
    DxItem,
  } from 'devextreme-vue/html-editor';
  
  import {
    DxButtonGroup,
    DxItem as DxButtonGroupItem,
  } from 'devextreme-vue/button-group';
@Component({
    components: {
        Editor,
        DxHtmlEditor,
     DxToolbar,
     DxMediaResizing,
     DxItem,
    }
})

export default class Nota extends Vue {


    public get nota() {
        return notaModule.nota;
    }
    isMobile() {
        return Utils.isMobile();
    }
    goBack() {
        this.$router.go(-1);
    }
    saveNota() {
        if (this.isMobile()) {
            this.$router.go(-1);        
        }
        notaModule.saveNota().then(() => {
            this.$toasted.show('Nota guardada correctamente', { duration: 600 });
        });
    }
    deleteNota() {
        //TODO: Confirmar primero
        this.$modal.show('dialog', {
            title: 'Eliminar nota!!',
            text: 'Estas seguro?',
            buttons: [
                {
                    title: 'Si',
                    handler: () => {
                        notaModule.deleteNota().then(() => {
                            this.$toasted.show('Nota eliminada correctamente', { duration: 600 });
                        })
                    }
                },
                {
                    title: 'No',       // Button title
                    default: true,    // Will be triggered by default if 'Enter' pressed.
                    //handler: () => {} // Button click handler
                },
            ]
        })

    }

}