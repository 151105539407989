import Vue from "vue";
import { Component } from "vue-property-decorator";
import notaModule from '@/store/modules/notaModule';
import listaModule from '@/store/modules/listaModule';
import Dto_Nota from '@/models/dtos/dto_nota';
import TarjetaModal from '@/shared/components/modal/Modal';
import  {PrsMto}  from '@manu72/iticoreconsum';
@Component({
    components: {
        PrsMto
    }
})

export default class Test extends Vue {
    private textSearch: string = "";
    private layout:any;
    private datos:any=[{id:1, titulo:'titulo1'},{id:2,titulo:'titulo2'}]
    created() {
        this.layout = [{
            dataField:'',
            caption:'Titulo'
        }]
    }
}