import { httpService } from '@/services/http.service';
import { wikiService } from '@/services/wiki.service';
import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/shared/components/main/Main.vue';
//import Tablero from './views/libreta/libreta';
import NotasMobile from './views/notasMobile/notasmobile';
import Login from './views/login/login';
import Nota from './views/nota/nota';
import test from './views/test/test';
Vue.use(Router)

const router = new Router({
  // linkExactActiveClass: 'is-active',
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes: [
    {
      path: '*',
      redirect: '/home',
      meta: { requiresAuth: true },
    },
    {
      path: '/',
      redirect: '/home',
      meta: { requiresAuth: true },
    },
    {
      path:'/login',
      name:'login',
      component:Login
    },
    {
      path: '/home',
      name: 'home',
      meta: { requiresAuth: true },
      component: Main,
      children:[
        {
          path:'/listanotas',
          name:'notas',
          component: NotasMobile,
        },
        {
          path:'/nota',
          name:'nota',
          component:Nota
        }
      ]
    },
    {
      path:'/test',
      name:'test',
      component:test
    },
    
  ]
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (localStorage.getItem("tokenwiki")) { //TODO: Enviar el token y validar que sea válido
      wikiService.ValidarToken().then(
        ret => {
          var response = ret;
          if (response.error)
            next({
              path: '/login',
              query: { redirect: to.fullPath }
            })
          else {
            next();
          }
        }
      )
      
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router