import Vue from "vue";
import { Component } from "vue-property-decorator";
import Nota from '../nota/nota';
import notaModule from '@/store/modules/notaModule';
import listaModule from '@/store/modules/listaModule';
import Dto_Nota from '@/models/dtos/dto_nota';
import TarjetaModal from '@/shared/components/modal/Modal';

@Component({
    components: {
        Nota
    }
})

export default class Notas extends Vue {
    private textSearch: string = "";
    public get libreta() {
        return listaModule.libretaSelected;
    }
    public get notas() {
        return listaModule.notas;
    }

    itemClick(e: any, evt: any) {
        notaModule.loadNota(e.id);
    }
    nuevaNota(evt: any) {
        notaModule.setNota(new Dto_Nota());
        this.$modal.show(
            TarjetaModal,
            {
                id: 0,
                listaId: listaModule.idlibretaSelected,
                listaTitulo: 'Nueva nota'
            },
            {
                draggable: false,
                scrollable: false,
                adaptative: true,
                width: '400px',
                height: 'auto',
                name: 'TarjetaModal'
            }
        )
        // let obj = new Dto_Nota();
        // obj.idlibreta = listaModule.idlibreta;
        // obj.titulo="nueva nota"
        // notaModule.setNota(obj);
        // evt.preventDefault();
    }
    search() {
        if (this.textSearch != null || this.textSearch != '') {
            listaModule.searchNotes(this.textSearch);
        } else {
            listaModule.loadLista();
        }
    }

}