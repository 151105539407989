import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import moment from 'moment'
import Vuex from 'vuex'
import { BootstrapVueIcons } from 'bootstrap-vue'
import { BootstrapVue } from 'bootstrap-vue'

// ESTILOS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/css/popover.scss';
import '@/assets/css/Admin.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

// COMPONENTES
var uiv = require("uiv");
import VModal from 'vue-js-modal';
Vue.config.productionTip = false
Vue.use(uiv);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true, dialog: true });
Vue.use(BootstrapVueIcons);
Vue.use(Vuex);
Vue.use(BootstrapVue);


import Toasted from 'vue-toasted';
Vue.use(Toasted)

// FILTROS
Vue.filter('formatDate', function (value: any) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
